import React,{Suspense} from 'react'
import 'bootstrap/dist/css/bootstrap.min.css';
import Loading from './pages/Loading';
import './assets/css/style.css'
import {Routes, Route} from 'react-router-dom'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';

const BillingCodes = React.lazy(() => import('./pages/BillingCodes'));
const Contact = React.lazy(() => import('./pages/Contact'));
const FAQ = React.lazy(() => import('./pages/FAQ'));
const Home = React.lazy(() => import('./pages/Home'));
const WhatIsCCM = React.lazy(() => import('./pages/WhatIsCCM'));
const BookDemo = React.lazy(() => import('./pages/BookDemo'))

const PrivacyPolicy = React.lazy(() => import('./pages/PrivacyPolicy'))
const TermsConditions = React.lazy(() => import('./pages/TermsConditions'))
// const MedicalTexting = React.lazy(() => import('./pages/MedicalTexting'))
const CcmEssential = React.lazy(() => import('./pages/CcmEssential'))



const Error = React.lazy(() => import('./pages/Error'))
function App() {
    return (
        <React.Fragment>
            <Suspense fallback={<Loading/>}>
                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="/what-is-ccm" element={<WhatIsCCM />} />
                    <Route path="/faqs" element={<FAQ />} />
                    <Route path="/billing-codes" element={<BillingCodes />} />
                    <Route path="/contact-us" element={<Contact />} />
                    <Route path="/book-a-demo" element={<BookDemo />} />
                    {/* <Route path="/medical-texting" element={<MedicalTexting />} /> */}
                    <Route path="/ccm-essentials" element={<CcmEssential />} />

                    <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                    <Route path="/terms-conditions" element={<TermsConditions />} />

                    <Route path="*" element={<Error />} />
                </Routes>
            </Suspense>
            <ToastContainer
                position="bottom-left"
                autoClose={5000}
            />
        </React.Fragment>
    );
}

export default App;
